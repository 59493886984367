import { BaseLayout, ContainedRow } from '../layouts';
import { IncomingMessage, ServerResponse } from 'http';
import { useEffect, useState } from 'react';

import Cookies from 'cookies';
import { Paginator } from '../shared';
import { ProductsRow } from '../shared';
import { TopMainBanner } from '../shared/TopMainBanner';
import { getMarketplace } from '../api/marketplace';
import { getStoreId } from '../api/server/getStoreId';
import { homeCategories } from '../api/server/homeCategories';
import { initialFetch } from '../api/server/initialFetch';
import { retrieveAllProducts } from '../api/products';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import type { state } from '../types/store/state';
import type { staticProps } from '../types/pages/props';
import { uiStateMap } from '../utils/uiStateMap';
import { uiType } from '../types/ui';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

const Home = () => {
  const router = useRouter();
  const metatags = useSelector((state: state) => state.ui.metatags);
  const domain = useSelector((state: state) => state.ui.domain);
  const storeName = useSelector((state: state) => state.ui.name);
  const homeProducts = useSelector((state: state) => state.products);

  const [windowWidth, setWindowWidth] = useState(global.innerWidth);
  const isMobile = windowWidth <= 639;

  useEffect(() => {
    const handleResize = () => setWindowWidth(global.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { page = '1' } = router.query;
  const prevPage = Number(Number(page)) - 1;
  const nextPage = Number(Number(page)) + 1;
  const totalPages = homeProducts.totalPages;

  const breadcrumbs = [
    {
      name: 'Home',
      url: '/',
    },
  ];

  return (
    <BaseLayout
      {...metatags}
      domain={domain}
      storeName={storeName}
      title={`${metatags.title} | ${storeName}`}
    >
      <TopMainBanner
        isCompact={false}
        title={storeName}
        breadcrumbs={breadcrumbs}
      />
      <ContainedRow>
        <ProductsRow title="" elements={homeProducts.results} />
      </ContainedRow>
      <Paginator
        prev={`/products/?page=${prevPage}`}
        prevDisabled={homeProducts.previous === null}
        next={`/products/?page=${nextPage}`}
        nextDisabled={homeProducts.next === null}
        currentPage={Number(page)}
        isCategory={false}
        categoryNumber={0}
        totalPages={totalPages}
        maxLength={isMobile ? 5 : 7}
      />
    
    </BaseLayout>
  );
};

export const getServerSideProps = async (context: staticProps) => {
  const translations = await serverSideTranslations(context.locale);
  const marketplace = await getMarketplace(context.req.headers.host);
  const cookies = new Cookies(context.req as unknown as IncomingMessage, context.res as ServerResponse);
  const sessionCookie = cookies.get('user_token');

  if (!marketplace.data.length) {
    return {
      props: {
        ...translations,
      },
      notFound: true,
    };
  }

  const marketplaceMap: uiType = uiStateMap(marketplace);
  const branchStoreId = await getStoreId(sessionCookie, marketplaceMap.storeId, marketplaceMap.businessId);

  if ( branchStoreId === 0) {
      return {
        notFound: true,
      };
  }

  const response = await initialFetch(branchStoreId);

  const initialCategories = await homeCategories(
    branchStoreId,
    response.categories.results[0].id,
    response.categories.results[1].id
  );

  let products;

  try {
    products = await retrieveAllProducts({
      id: branchStoreId,
      page: context.query.page ? context.query.page : '1',
    });
  } catch (error: any) {
    if (error.response.status === 404) {
      return {
        notFound: true,
      };
    }
    throw new Error(error);
  }

  const preloadedState = JSON.parse(
    JSON.stringify({
      ui: marketplaceMap,
      categories: response.categories.results,
      branchStore: response.branchStoreDetail,
      branchStoreSchedule: response.branchStoreSchedule.results,
      products,
    })
  );

  return {
    props: {
      ...translations,
      preloadedState: preloadedState,
      initialCategories,
    },
  };
};

export default Home;
